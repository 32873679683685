import React from "react";
import { Container, Grid, Flex, Link, Stack, Text } from "basis";

import { Icon } from "../../../components";
import { NavigationalLayout } from "../components";

import { SECTION_LIST } from "./constants";

const Scene = () => {
  return (
    <NavigationalLayout>
      <Grid preset="page" rowsGap={8}>

        <Grid.Item colSpan="all">
          <Text as="h3" textStyle="heading3">Training Guides</Text>
        </Grid.Item>

        <Grid.Item colSpan="all">
          <Container bg="white" padding="8">
            <Stack gap="6">
              {SECTION_LIST.map(section => (
                <>
                  <Text as="h4" textStyle="heading4">{section.title}</Text>
                  <Stack gap="6" margin="6 0 0 0">
                    {section.itemList.map(item => (
                      <Flex>
                        <Link href={item.link} newTab margin="0 2 0 0">{item.text}</Link>
                        <Icon type="external" display="content" />
                      </Flex>
                    ))}
                  </Stack>
                </>
              ))}
            </Stack>
          </Container>
        </Grid.Item>

      </Grid>
    </NavigationalLayout>
  );
};

export default Scene;
