import { resourceBaseUrl } from "../../../core/config";

export const SECTION_LIST = [
  {
    title: "Partner Hub Guide",
    itemList: [
      {
        text: "Login Guide",
        link: `${resourceBaseUrl}/interest-free/merchant-resources/partner-hub-resources/login-guide/`,
      },
      {
        text: "How to create Orders",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=401%3A502&node-id=456-1576&viewport=-2767%2C239%2C0.5&scaling=scale-down&starting-point-node-id=402%3A503&hide-ui=1&_gl=1*1ryfb48*_ga*MTM2OTc2MTAxNi4xNjg3ODI0MTkz*_ga_86RQZBJYRC*MTY4ODUyMjQ3MC42LjAuMTY4ODUyMjc0NS4wLjAuMA..",
      },
      {
        text: "How to perform Manual Payment",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=401%3A502&node-id=995-1492&viewport=-2767%2C239%2C0.5&scaling=scale-down&starting-point-node-id=402%3A503&hide-ui=1&_gl=1*1ryfb48*_ga*MTM2OTc2MTAxNi4xNjg3ODI0MTkz*_ga_86RQZBJYRC*MTY4ODUyMjQ3MC42LjAuMTY4ODUyMjc0NS4wLjAuMA..",
      },
      {
        text: "How to Manage Orders",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=401%3A502&node-id=735-1781&viewport=-2767%2C239%2C0.5&scaling=scale-down&starting-point-node-id=402%3A503&hide-ui=1&_gl=1*1ryfb48*_ga*MTM2OTc2MTAxNi4xNjg3ODI0MTkz*_ga_86RQZBJYRC*MTY4ODUyMjQ3MC42LjAuMTY4ODUyMjc0NS4wLjAuMA..",
      },
    ],
  },
  {
    title: "User Experience Flow - AU",
    itemList: [
      {
        text: "Instore POS User Experience",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=209%3A2527&node-id=298-2603&viewport=606%2C-4%2C0.07&scaling=scale-down&starting-point-node-id=298%3A2603&hide-ui=1",
      },
      {
        text: "Online Integration User Experience",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=2603%3A2183&node-id=2603-2184&viewport=1609%2C313%2C0.36&scaling=min-zoom&starting-point-node-id=2603%3A2184",
      },
    ],
  },
  {
    title: "User Experience Flow - NZ",
    itemList: [
      {
        text: "Instore POS User Experience",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=309%3A2604&node-id=309-2605&viewport=606%2C-5%2C0.07&scaling=scale-down&starting-point-node-id=309%3A2605&hide-ui=1",
      },
      {
        text: "Online Integration User Experience",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=3147%3A4868&node-id=3147-4869&viewport=1201%2C255%2C0.16&scaling=min-zoom&starting-point-node-id=3147%3A4869",
      },
    ],
  },
  {
    title: "Application Guide",
    itemList: [
      {
        text: "Application Guide - AU",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=401%3A502&node-id=3800-5402&viewport=-2767%2C239%2C0.5&scaling=scale-down&starting-point-node-id=402%3A503&hide-ui=1&_gl=1*1ryfb48*_ga*MTM2OTc2MTAxNi4xNjg3ODI0MTkz*_ga_86RQZBJYRC*MTY4ODUyMjQ3MC42LjAuMTY4ODUyMjc0NS4wLjAuMA..",
      },
      {
        text: "Application Guide - NZ",
        link:
          "https://www.figma.com/proto/b04XrG7MKmXhVy3jwToddR/Training-%E2%80%93-Latitude-%26-Gem-IF-Booklets?page-id=1015%3A1898&node-id=3800-8518&viewport=959%2C-226%2C0.25&scaling=scale-down&starting-point-node-id=1015%3A2003&hide-ui=1",
      },
    ],
  },
];
